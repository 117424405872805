.languages-popup-page {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.languages-popup-page .languages-popup-container {
  width: 20rem;
  height: fit-content;
  height: 29rem;
  max-height: 90vh;
  max-width: 90%;
  border-radius: 35px;
  background-color: var(--secondary-color);
  color: var(--text-color-over-secondary-color);
  padding: 1.5rem;
}

.languages-popup-content-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.languages-popup-page.main-design .languages-popup-container {
  background-color: var(--secondary-color-main-design);
  color: var(--text-color-over-secondary-color-main-design);
}

.languages-popup-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.languages-popup-page .languages-popup-title {
    font: var(--font-bold);
    font-size: var(--font-seventeen);
    margin-top: 10px;
}

.languages-popup-page.main-design .languages-popup-title {
    font: var(--font-bold-main-design);
    font-size: var(--font-seventeen);
}

.languages-popup-list-container {
    width: 100%;
    overflow: auto;
    margin-top: 60px;
}

.languages-popup-list-container ul {
    list-style: none;
}

.languages-popup-page .languages-popup-list-container ul li {
    padding: 15px;
    border-bottom: 1px solid rgba(var(--text-color-over-secondary-color-rgb-values), 0.1);
    font: var(--font-semibold);
    font-size: var(--font-fourteen);
    cursor: pointer;
}

.languages-popup-page.main-design .languages-popup-list-container ul li {
     border-bottom: 1px solid rgba(var(--text-color-over-secondary-color-rgb-values-main-design), 0.1);
     font: var(--font-semibold-main-design);
     font-size: var(--font-fourteen);
}

.languages-popup-page .languages-popup-list-container ul li.selected-language {
    background-color: var(--main-color);
    color:var(--text-color-over-main-color);
    border-radius: 5px;
}

.languages-popup-page.main-design .languages-popup-list-container ul li.selected-language {
    background-color: var(--main-color-main-design);
    color:var(--text-color-over-main-color-main-design);
}

.languages-popup-btns-container {
    margin-top:30px;
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.languages-popup-page .languages-popup-continue-btn {
    background-color: var(--main-color);
    color: var(--secondary-color);
    width: 114px;
    height: 37px;
    border-radius: 10px;
    margin-bottom: 10px;
    font: var(--font-semibold);
    font-size: var(--font-sixteen);
}

.languages-popup-page.main-design .languages-popup-continue-btn {
    background-color: var(--main-color-main-design);
    color: var(--secondary-color-main-design);
    font: var(--font-semibold-main-design);
}

.languages-popup-page.main-design .languages-popup-close-btn {
    font: var(--font-semibold-main-design);
    background-color: var(--secondary-color-main-design);
    color: var(--text-color-over-secondary-color-main-design);
    font-size: var(--font-twelve);
}
